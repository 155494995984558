<template lang="pug">
om-modal(
  name="sub-account-visitor-limit"
  color="gray"
  :title="$t('visitorLimitPlaceholder')"
  @beforeOpen="beforeOpen"
)
  template(slot="modal-body")
    .d-flex.align-items-end
      .flex-grow-2
        .font-size-0--875 {{ $t('visitorLimit') }}
        .form-check.my-1
          input#noLimitCheck.form-check-input(type="checkbox" v-model="noLimit")
          label.font-size-0--875.form-check-label(for="noLimitCheck") {{ $t('noLimit') }}
        input.form-control.form-control-lg(
          :class="{ 'is-invalid': $v.limitsMax.$error || $v.agencyAssignable.$error }"
          type="number"
          :placeholder="$t('visitorLimitPlaceholder')"
          :aria-label="$t('visitorLimitPlaceholder')"
          v-model.number="limitsMax"
          @keyup.enter="updateSubAccount()"
          :disabled="noLimit"
        )
      .flex-grow-1.pl-4
        .card
          .card-body
            div {{ $t('availableLimit') }}
            .font-weight-bold {{ agencyAssignable | thousandSep }} / {{ agencyLimits.maxVisitor | thousandSep }}

  template(slot="modal-footer")
    .d-flex.justify-content-end
      om-button(primary @click="updateSubAccount()") {{ $t('apply') }}
</template>

<script>
  import UPDATE_SUBACCOUNT from '@/graphql/UpdateSubAccount.gql';
  import { minValue } from 'vuelidate/lib/validators';

  export default {
    data() {
      return {
        subAccountId: null,
        agencyLimits: {},
        totalAssigned: 0,
        limitsMax: 0,
        oldLimitMax: 0,
        limitsUsed: 0,
        noLimit: false,
      };
    },
    computed: {
      agencyAssignable() {
        return this.agencyLimits.maxVisitor - this.totalAssigned + this.limitDifference;
      },
      limitDifference() {
        return this.oldLimitMax - this.limitsMax;
      },
    },

    validations: {
      limitsMax: {
        isCool(value) {
          return this.noLimit || (value !== null && value !== '');
        },
        minValue: minValue(0),
      },
      agencyAssignable: {
        minValue: minValue(0),
      },
    },

    methods: {
      beforeOpen(event) {
        this.subAccountId = event.params.subAccountId;
        this.agencyLimits = event.params.agencyLimits;
        this.limitsMax = event.params.subAccountLimits.maxVisitor;
        this.noLimit = this.limitsMax === null;
        this.oldLimitMax = this.limitsMax;
        this.limitsUsed = event.params.subAccountLimits.usedVisitor;
        this.totalAssigned = event.params.totalAssigned;
      },
      updateSubAccount() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
        } else {
          this.$apollo
            .mutate({
              mutation: UPDATE_SUBACCOUNT,
              variables: {
                input: {
                  _id: this.subAccountId,
                  limits: {
                    maxVisitor: this.noLimit ? null : this.limitsMax,
                    usedVisitor: this.limitsUsed,
                  },
                },
              },
            })
            .then(() => {
              this.$emit('updateSubAccountLimit');
              this.$modal.hide('sub-account-visitor-limit');
            });
        }
      },
    },
  };
</script>
