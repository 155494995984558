<template lang="pug">
.brand-users-holder
  .text-center(v-if="users.length === 0") No results
  .brand-user-row.pr-2(v-for="user in usersFiltered")
    .brand-user-row-monogram {{ getMonogram(user) }}
    template(v-if="isInvitedUser(user)")
      .brand-user-row-info.pr-3
        .brand-user-row-name {{ user.email + ' ' + (user.count !== undefined ? '(' + user.count + ')' : '') }} ({{ $t('invited') }})
      .brand-user-row-actions
        slot(name="actions" :row="{ user }")
          span.oi.oi-trash.cursor-pointer(aria-hidden="true" @click="removeInvitation(user)")
    template(v-else-if="isSuggestedUser(user)")
      .brand-user-row-info.pr-3
        .brand-user-row-name {{ user.firstName + ' ' + user.lastName }}
        .brand-user-row-email {{ user.email }}
      .brand-user-row-actions
        slot(name="actions" :row="{ user }")
    template(v-else)
      .brand-user-row-info.pr-3
        .brand-user-row-name {{ getFullNameByLocale(user.login.firstName, user.login.lastName, user.login.locale) }}
        .brand-user-row-email {{ user.login.email }}
      .brand-user-row-actions
        slot(name="actions" :row="{ user }")
          template(v-if="user.role")
            span(v-if="user.role === 'owner'") {{ $t('owner') }}
            span(v-else-if="user.login._id === account.login._id") {{ $t('you') }}
            span.oi.oi-trash.cursor-pointer(v-else aria-hidden="true" @click="removeUser(user)")
          template(v-else)
            .brand-link(@click="addUser(user)") {{ $t('add') }}
</template>

<script>
  import REMOVE_USER from '@/graphql/RemoveUser.gql';
  import REMOVE_INVITATION from '@/graphql/RemoveInvitation.gql';
  import GET_USERS_WITH_SUGGESTIONS from '@/graphql/GetUsersWithSuggestions.gql';
  import GET_USERS from '@/graphql/GetUsers.gql';
  import ADD_USER from '@/graphql/AddUser.gql';
  import fullName from '@/mixins/fullName';
  import { getBrandedClassString } from '@/components/Elements/Button';

  export default {
    mixins: [fullName],

    props: {
      users: { type: Array, required: true },
      subAccountId: { type: String },
      account: { type: Object },
    },

    computed: {
      usersFiltered() {
        return this.users.filter((user) => user.email || user.login);
      },
    },

    methods: {
      isInvitedUser(user) {
        return (
          user.login === undefined && user.firstName === undefined && user.lastName === undefined
        );
      },

      isSuggestedUser(user) {
        return (
          user.login === undefined && user.firstName !== undefined && user.lastName !== undefined
        );
      },

      getMonogram(user) {
        if (user.login) {
          const [firstPart, lastPart] = this.getFullNameByLocale(
            user.login.firstName,
            user.login.lastName,
            user.login.locale,
          ).split(' ');
          return firstPart.charAt(0).toUpperCase() + lastPart.charAt(0).toUpperCase();
        }
        if (user.email) {
          const emailParts = user.email.split('@');
          const prefixParts = emailParts[0].split('.');
          if (prefixParts.length >= 2) {
            return prefixParts[0].charAt(0).toUpperCase() + prefixParts[1].charAt(0).toUpperCase();
          }
          return emailParts[0].charAt(0).toUpperCase();
        }
      },
      removeInvitation(user) {
        this.$modal.show('dialog', {
          text: this.$t('confirmationDialog'),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => {
                this.$apollo
                  .mutate({
                    mutation: REMOVE_INVITATION,
                    variables: {
                      accountId: this.subAccountId,
                      email: user.email,
                    },
                    refetchQueries: [
                      { query: GET_USERS, variables: { accountId: this.subAccountId } },
                      {
                        query: GET_USERS_WITH_SUGGESTIONS,
                        variables: { accountId: this.subAccountId },
                      },
                    ],
                  })
                  .then(() => {
                    this.$emit('refreshSubAccounts');
                    this.$modal.hide('dialog');
                    this.$notify({
                      type: 'success',
                      text: this.$t('notifications.removeSuccess'),
                    });
                  })
                  .catch(() => {
                    this.$notify({
                      type: 'error',
                      title: this.$t('notifications.removeError'),
                    });
                  });
              },
            },
            {
              title: this.$t('cancel'),
              class: getBrandedClassString({ secondary: true }),
              default: true,
            },
          ],
        });
      },
      removeUser(user) {
        this.$modal.show('dialog', {
          text: this.$t('confirmationDialog'),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => {
                this.$apollo
                  .mutate({
                    mutation: REMOVE_USER,
                    variables: {
                      accountId: this.subAccountId,
                      loginId: user.login._id,
                    },
                    refetchQueries: [
                      { query: GET_USERS, variables: { accountId: this.subAccountId } },
                      {
                        query: GET_USERS_WITH_SUGGESTIONS,
                        variables: { accountId: this.subAccountId },
                      },
                    ],
                  })
                  .then(() => {
                    this.$emit('refreshSubAccounts');
                    this.$modal.hide('dialog');
                    this.$notify({
                      type: 'success',
                      text: this.$t('notifications.removeSuccess'),
                    });
                  })
                  .catch(() => {
                    this.$notify({
                      type: 'error',
                      title: this.$t('notifications.removeError'),
                    });
                  });
              },
            },
            {
              title: this.$t('cancel'),
              class: getBrandedClassString({ secondary: true }),
              default: true,
            },
          ],
        });
      },

      addUser(user) {
        this.$apollo
          .mutate({
            mutation: ADD_USER,
            variables: {
              accountId: this.subAccountId,
              loginId: user.login._id,
            },
            refetchQueries: [
              { query: GET_USERS, variables: { accountId: this.subAccountId } },
              { query: GET_USERS_WITH_SUGGESTIONS, variables: { accountId: this.subAccountId } },
            ],
          })
          .then(() => {
            this.$emit('refreshSubAccounts');
            this.$notify({
              type: 'success',
              text: this.$t('notifications.addSuccess'),
            });
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: this.$t('notifications.addError'),
            });
          });
      },
    },
  };
</script>
