var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('om-table',{attrs:{"items":_vm.accounts,"columns":_vm.columns,"allItemsCount":_vm.accountCount,"selectable":false,"pagination":_vm.pagination,"sorting":_vm.sorting,"columnClasses":_vm.columnClasses},on:{"sortingChange":function($event){_vm.sorting = $event},"paginationChange":function($event){_vm.pagination = $event}},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"brand-sub-account-name brand-link",attrs:{"title":rowData.row.name},on:{"click":function($event){return _vm.logInto(rowData.row)}}},[_vm._v(_vm._s(rowData.row.name))])]}},{key:"users",fn:function(ref){
var rowData = ref.rowData;
return [_c('popper',{attrs:{"trigger":"click","options":{ placement: 'top' },"transition":"fade","enter-active-class":"fade-enter-active","leave-active-class":"fade-leave-active"},on:{"created":function($event){return _vm.usersPopoverCreated($event, rowData.row._id)}}},[_c('div',{staticClass:"popper brand-users-popover"},[_c('div',{staticClass:"brand-users-header"},[_c('div',[_vm._v(_vm._s(_vm.$t('users')))]),_c('div',{staticClass:"brand-users-settings"},[_c('span',{staticClass:"oi oi-cog cursor-pointer",attrs:{"title":"settings","aria-hidden":"true"},on:{"click":function($event){return _vm.showSubAccountUserModal(rowData.row)}}})])]),_c('user-rows',{attrs:{"users":_vm.users,"subAccountId":rowData.row._id,"account":_vm.account},on:{"refreshSubAccounts":_vm.fetchSubAccounts}})],1),_c('span',{staticClass:"cursor-pointer",attrs:{"slot":"reference"},slot:"reference"},[_c('span',{staticClass:"brand-link"},[_vm._v(_vm._s(_vm._f("thousandSep")(rowData.row[rowData.column.key])))])])])]}},{key:"limits.maxVisitor",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"brand-link",on:{"click":function($event){return _vm.showSubAccountVisitorLimitModal(rowData.row)}}},[(rowData.row.limits.maxVisitor === null)?_c('span',[_vm._v(_vm._s(_vm.$t('noLimit')))]):_c('span',[_vm._v(_vm._s(_vm._f("thousandSep")(rowData.row.limits.maxVisitor)))])])]}},{key:"limits.usedVisitor",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',{staticClass:"progress flex-grow-1 mx-3"},[_c('div',{staticClass:"progress-bar",style:({ width: _vm.visitorPercent(rowData.row.limits.usedVisitor, rowData.row.limits.maxVisitor) + '%' }),attrs:{"role":"progressbar","aria-valuenow":_vm.visitorPercent(rowData.row.limits.usedVisitor, rowData.row.limits.maxVisitor),"aria-valuemin":"0","aria-valuemax":"100"}})]),_c('div',{staticClass:"flex-column"},[_vm._v(_vm._s(_vm._f("thousandSep")(rowData.row.limits.usedVisitor)))])])]}},{key:"options",fn:function(ref){
var rowData = ref.rowData;
return [_c('popper',{attrs:{"trigger":"click","options":{ placement: 'left' }},on:{"created":function($event){_vm.popoverInstance = $event}}},[_c('div',{staticClass:"popper brand-settings-popover"},[_c('div',{staticClass:"setting-link",on:{"click":function($event){return _vm.showSubAccountRenameModal(rowData.row)}}},[_vm._v(_vm._s(_vm.$t('rename')))]),_c('div',{staticClass:"setting-link",on:{"click":function($event){return _vm.showSubAccountUserModal(rowData.row)}}},[_vm._v(_vm._s(_vm.$t('manageUsers')))]),_c('div',{staticClass:"setting-link",on:{"click":function($event){return _vm.showSubAccountVisitorLimitModal(rowData.row)}}},[_vm._v(_vm._s(_vm.$t('setVisitorLimit')))]),_c('div',{staticClass:"setting-link",on:{"click":function($event){return _vm.removeSubAccount(rowData.row)}}},[_vm._v(_vm._s(_vm.$t('delete')))])]),_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_c('span',{staticClass:"oi oi-cog brand-settings-icon cursor-pointer",attrs:{"title":"settings","aria-hidden":"true"}})])])]}}])}),_c('sub-account-rename-modal',{on:{"refreshSubAccounts":_vm.fetchSubAccounts}}),_c('sub-account-user-modal',{on:{"refreshSubAccounts":_vm.fetchSubAccounts}}),_c('sub-account-visitor-limit-modal',{on:{"updateSubAccountLimit":_vm.updateSubAccountLimit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }