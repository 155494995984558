<template lang="pug">
om-modal(
  name="new-sub-account-modal"
  color="gray"
  @beforeOpen="beforeOpen"
  :clickToClose="false"
  :scrollable="true"
)
  template(slot="modal-header")
    .brand-modal-title {{ $t('newSubAccount') }}
    .brand-modal-page-count {{ `${currentPage}/${pageCount}` }}
  template(v-if="currentPage === 1")
    template(slot="modal-body")
      .mb-2.font-size-0--875 {{ $t('subAccountName') }}
      input.form-control.form-control-lg(
        :class="{ 'is-invalid': $v.name.$error }"
        type="text"
        :placeholder="$t('newNamePlaceholder')"
        :aria-label="$t('newNamePlaceholder')"
        v-model.trim="name"
      )
      .d-flex.align-items-end.mt-2
        .flex-grow-2
          .font-size-0--875 {{ $t('visitorLimit') }}
          .form-check.my-1
            input#noLimitCheck.form-check-input(type="checkbox" v-model="noLimit")
            label.font-size-0--875.form-check-label(for="noLimitCheck") {{ $t('noLimit') }}
          div(@click="noLimit = false")
            input.form-control.form-control-lg(
              :class="{ 'is-invalid': $v.visitorLimit.$error }"
              type="number"
              :placeholder="$t('visitorLimitPlaceholder')"
              :aria-label="$t('visitorLimitPlaceholder')"
              v-model.number="visitorLimit"
              @input="dynamicInputValue"
              :disabled="noLimit"
            )
        .flex-grow-1.pl-4
          .card
            .card-body
              div {{ $t('availableLimit') }}
              .font-weight-bold {{ dynamicAgencyAssignable | thousandSep }} / {{ agencyLimits.maxVisitor | thousandSep }}
    template(slot="modal-footer")
      .text-right.d-flex.justify-content-end
        om-button.mr-3(secondary @click="$modal.hide('new-sub-account-modal')") {{ $t('cancel') }}
        om-button(primary @click="nextPage()") {{ $t('next') }}
  template(v-if="currentPage === 2")
    template(slot="modal-body")
      .input-group
        input.form-control.form-control-lg(
          type="text"
          :placeholder="$t('searchUsersPlaceholder')"
          :aria-label="$t('searchUsersPlaceholder')"
          v-model="userFilter"
        )
        // .input-group-append
        //   button.btn.btn-outline-secondary(type='button')
        //     span(class="oi oi-magnifying-glass cursor-pointer" title="close" aria-hidden="true")
      .mt-3 {{ $t('userSuggestionsPlaceholder') }} ({{ suggestedUsers && suggestedUsers.length }})
      user-rows.my-3(:users="filteredSuggestedUsers")
        template(slot="actions" slot-scope="{ row }")
          template(v-if="row.user._id")
            span(v-if="row.user._id === agencyOwner") {{ $t('owner') }}
            span(v-else-if="row.user._id === $store.state.account.login._id") {{ $t('you') }}
            template(v-else)
              span.oi.oi-trash.cursor-pointer(
                v-if="users.includes(row.user._id)"
                aria-hidden="true"
                @click="removeUser(row.user)"
              )
              .brand-link(v-else @click="addUser(row.user)") {{ $t('add') }}
          template(v-else)
            span.oi.oi-trash.cursor-pointer(
              v-if="invitations.includes(row.user.email)"
              aria-hidden="true"
              @click="removeNewInvitation(row.user)"
            )
            .brand-link(v-else @click="addInvitation(row.user)") {{ $t('add') }}
    template(slot="modal-footer")
      .mb-1 {{ $t('invite') }}
      .input-group.mb-4
        input.form-control.form-control-lg(
          type="text"
          :class="{ 'is-invalid': $v.invitedUser.$error }"
          :placeholder="$t('inviteByEmailPlaceholder')"
          :aria-label="$t('inviteByEmailPlaceholder')"
          @keyup.enter="inviteUser"
          v-model="invitedUser"
        )
        .input-group-append
          button.btn.btn-outline-secondary.brand-btn-secondary-outline(
            type="button"
            @click="inviteUser"
            :disabled="invitedUser === ''"
          ) {{ $t('go') }}
      .d-flex
        .flex-shrink
          om-button.px-3(secondary @click="prevPage()") {{ $t('back') }}
        .d-flex.flex-grow-1.justify-content-end
          //- button.btn.btn-secondary.mr-3(:disabled="this.users.length !== 0 || this.invitations.length !== 0" @click="save()") {{ $t('skip') }}
          om-button.px-3(primary @click="save()") {{ $t('create') }}
</template>

<script>
  import GET_USER_SUGGESTIONS from '@/graphql/GetUserSuggestions.gql';
  import ADD_SUBACCOUNT from '@/graphql/AddSubAccount.gql';
  import UserRows from '@/components/UserRows.vue';
  import { required, email } from 'vuelidate/lib/validators';
  import { USER_ROLE } from '@/utils/constant';

  export default {
    components: {
      UserRows,
    },

    props: {
      agencyLimits: { type: Object },
      agencyAssignable: { type: Number },
    },

    data() {
      return {
        pageCount: 2,
        currentPage: 1,
        userFilter: '',
        suggestions: {},

        name: '',
        visitorLimit: '',
        noLimit: true,
        users: [],
        invitations: [],
        invitedUser: '',
      };
    },

    computed: {
      mapInvitations() {
        return this.invitations.map((email) => {
          return { email };
        });
      },

      agencyOwner() {
        return this.suggestions && this.suggestions.agencyOwner ? this.suggestions.agencyOwner : '';
      },

      suggestedUsers() {
        return this.suggestions?.users ? this.suggestions.users.concat(this.mapInvitations) : [];
      },

      dynamicAgencyAssignable() {
        if (this.visitorLimit < 0) {
          return NaN;
        }
        return this.agencyAssignable - this.visitorLimit;
      },

      filteredSuggestedUsers() {
        const userFilter = this.userFilter.toLowerCase();
        const containsUserFilter = (suggestedUser, userFilter) => {
          // eslint-disable-next-line
          let { firstName, lastName, email } = suggestedUser;
          firstName = firstName ? firstName.toLowerCase() : '';
          lastName = lastName ? lastName.toLowerCase() : '';

          return (
            firstName.includes(userFilter) ||
            lastName.includes(userFilter) ||
            email.includes(userFilter)
          );
        };
        return userFilter !== ''
          ? this.suggestedUsers.filter((suggestedUser) =>
              containsUserFilter(suggestedUser, userFilter),
            )
          : this.suggestedUsers;
      },
    },

    validations: {
      name: {
        required,
      },
      visitorLimit: {
        isCool(value) {
          return (
            this.noLimit ||
            (value !== null && value !== '' && value > 0 && this.dynamicAgencyAssignable >= 0)
          );
        },
      },
      invitedUser: {
        isCoolEmail(v) {
          return email(v.toLowerCase());
        },
      },
    },

    methods: {
      beforeOpen() {
        this.resetFields();
        this.$apollo.queries.suggestions.refetch();
      },

      addUser(suggestedUser) {
        this.users.push(suggestedUser._id);
      },

      removeUser(suggestedUser) {
        const index = this.users.indexOf(suggestedUser._id);

        if (index !== -1) {
          this.users.splice(index, 1);
        }
      },

      addInvitation(user) {
        this.invitations.push(user.email);
      },

      removeNewInvitation(user) {
        const index = this.invitations.indexOf(user.email);

        if (index !== -1) {
          this.invitations.splice(index, 1);
        }
      },

      inviteUser() {
        if (this.invitedUser === '') {
          return;
        }
        this.$v.invitedUser.$touch();
        if (this.$v.invitedUser.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
        } else if (this.invitations.includes(this.invitedUser.toLowerCase())) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.alreadyInvited'),
          });
        } else {
          this.invitations.push(this.invitedUser.toLowerCase());
          this.invitedUser = '';
        }
      },

      prevPage() {
        this.currentPage--;
      },

      nextPage() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
        } else {
          this.currentPage++;
        }
      },

      resetFields() {
        this.currentPage = 1;
        this.userFilter = '';
        this.name = '';
        this.visitorLimit = '';
        this.noLimit = true;
        this.users = [];
        this.invitations = [];

        this.$v.$reset();
      },

      save() {
        const users = this.users.map((userId) => {
          return { loginId: userId, role: USER_ROLE.WRITE };
        });

        this.$apollo
          .mutate({
            mutation: ADD_SUBACCOUNT,
            variables: {
              input: {
                name: this.name,
                limits: {
                  maxVisitor: this.noLimit ? null : this.visitorLimit,
                },
                users,
                invitations: this.invitations,
              },
            },
          })
          .then(() => {
            this.$emit('refreshSubAccounts');
            this.$modal.hide('new-sub-account-modal');
            this.$notify({
              type: 'success',
              text: this.$t('notifications.saveSuccess'),
            });
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: this.$t('notifications.saveError'),
            });
          });
      },

      dynamicInputValue(event) {
        if (event.target.value > this.agencyAssignable) {
          this.visitorLimit = this.agencyAssignable;
        }
      },
    },

    apollo: {
      suggestions: {
        query: GET_USER_SUGGESTIONS,
      },
    },
  };
</script>
