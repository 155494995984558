<template lang="pug">
om-modal(name="sub-account-user" color="gray" :title="$t('manageUsers')" @beforeOpen="beforeOpen")
  template(slot="modal-body")
    .input-group
      input.form-control.form-control-lg(
        type="text"
        :placeholder="$t('searchUsersPlaceholder')"
        :aria-label="$t('searchUsersPlaceholder')"
        v-model="userFilter"
      )
    .mt-3 {{ $t('users') }} ({{ subAccountUserCount }})
    user-rows.my-3(
      :users="filteredUsers"
      :subAccountId="subAccountId"
      :account="account"
      @refreshSubAccounts="refreshSubAccounts"
    )
  template(slot="modal-footer")
    .mb-1 {{ $t('invite') }}
    .input-group.pb-3
      input.form-control.form-control-lg(
        type="text"
        :class="{ 'is-invalid': $v.invitedUserEmail.$error }"
        :placeholder="$t('inviteByEmailPlaceholder')"
        :aria-label="$t('inviteByEmailPlaceholder')"
        @keyup.enter="inviteUser"
        v-model="invitedUserEmail"
      )
      .input-group-append
        button.btn.btn-outline-secondary(type="button" @click="inviteUser") {{ $t('go') }}
</template>

<script>
  import GET_USERS_WITH_SUGGESTIONS from '@/graphql/GetUsersWithSuggestions.gql';
  import GET_INVITATIONS from '@/graphql/GetInvitations.gql';
  import ADD_INVITATION from '@/graphql/AddInvitation.gql';
  import UserRows from '@/components/UserRows.vue';
  import { email } from 'vuelidate/lib/validators';

  export default {
    components: {
      UserRows,
    },

    data() {
      return {
        subAccountId: '',
        userFilter: '',
        account: {},
        users: [],
        invitations: [],
        invitedUserEmail: '',
      };
    },

    computed: {
      subAccountUserCount() {
        return this.users.filter((u) => u.role !== null).length;
      },

      allUsers() {
        return this.users.concat(this.invitations);
      },

      filteredUsers() {
        const userFilter = this.userFilter.toLowerCase();
        const containsUserFilter = (user, userFilter) => {
          if (user.login !== undefined) {
            return (
              user.login.firstName.toLowerCase().includes(userFilter) ||
              user.login.lastName.toLowerCase().includes(userFilter) ||
              user.login.email.includes(userFilter)
            );
          }
          return user.email.includes(userFilter);
        };
        return userFilter !== ''
          ? this.allUsers.filter((user) => containsUserFilter(user, userFilter))
          : this.allUsers;
      },
    },

    validations: {
      invitedUserEmail: {
        isCoolEmail(v) {
          return email(v.toLowerCase());
        },
        isCool(value) {
          return value !== null && value !== '';
        },
      },
    },

    methods: {
      beforeOpen(event) {
        this.subAccountId = event.params.subAccountId;
        this.account = event.params.account;
        this.$v.$reset();

        this.$apollo.addSmartQuery('users', {
          query: GET_USERS_WITH_SUGGESTIONS,
          variables: {
            accountId: this.subAccountId,
          },
        });
        this.$apollo.addSmartQuery('invitations', {
          query: GET_INVITATIONS,
          variables: {
            accountId: this.subAccountId,
          },
        });
      },

      inviteUser() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
        } else {
          const userEmail = this.invitedUserEmail.toLowerCase();
          this.invitedUserEmail = '';

          this.$apollo
            .mutate({
              mutation: ADD_INVITATION,
              variables: {
                accountId: this.subAccountId,
                email: userEmail,
              },
              refetchQueries: [
                { query: GET_INVITATIONS, variables: { accountId: this.subAccountId } },
              ],
            })
            .then(({ data: { addInvitation } }) => {
              this.$apollo.queries.invitations.refetch();
              this.$apollo.queries.users.refetch();
              if (addInvitation.success) {
                this.$notify({
                  type: 'success',
                  text: this.$t('notifications.invitationSuccess'),
                });
              } else if (addInvitation.message) {
                this.$notify({
                  type: 'error',
                  text: this.$t(`notifications.${addInvitation.message}`),
                });
              }
            })
            .catch(() => {
              this.$notify({
                type: 'error',
                title: this.$t('notifications.invitationError'),
              });
            });
        }
      },

      refreshSubAccounts() {
        this.$apollo.queries.invitations.refetch();
        this.$apollo.queries.users.refetch();
        this.$emit('refreshSubAccounts');
      },
    },
  };
</script>
