<template lang="pug">
om-modal(name="invite-affiliate" color="light" modalClasses="invite-affiliate-modal")
  template(slot="modal-header")
    .row
      .col
        .title.text-left {{ $t('sendLink') }}
    .brand-modal-action-icon
      span.cursor-pointer(
        @click="$modal.hide('invite-affiliate')"
        title="close"
        aria-hidden="true"
      )
        close-icon(:width="12" :height="12")
  template(slot="modal-body")
    .d-flex.flex-column.align-items-center.mt-2
      .mb-4.font-weight-bold(v-if="!affiliateLink") {{ $t('affiliate.noLinkAvailable') }}
      template(v-else)
        input.form-control.form-control-md.mb-2.text-center.bg-white(
          disabled="disabled"
          :value="affiliateLink"
        )
        om-button.my-4(
          primary
          v-clipboard:copy="affiliateLink"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          :title="$t('affiliate.copyUrl')"
        ) {{ $t('affiliate.copyUrl') }}
    .text-center {{ $t('sendLinkLead') }}
  template(slot="modal-footer")
</template>

<script>
  export default {
    props: ['partnerId'],

    computed: {
      affiliateLink() {
        return this.partnerId
          ? `${window.location.protocol}//${window.location.hostname}/register/${this.$i18n.locale}?p_id=${this.partnerId}`
          : null;
      },
    },

    methods: {
      onCopy() {
        this.$notify({
          type: 'success',
          text: this.$t('notifications.urlCopied'),
        });
      },

      onError() {
        this.$notify({
          type: 'error',
          text: this.$t('notifications.clipboardCopyError'),
        });
      },
    },
  };
</script>

<style lang="sass">
  .invite-affiliate-modal
    .brand-modal-footer
      padding: 0 !important
      padding-bottom: 20px !important
</style>
