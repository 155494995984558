<template lang="pug">
om-modal(name="sub-account-rename" @beforeOpen="beforeOpen" title="Rename sub-account")
  template(slot="modal-body")
    input.form-control.form-control-lg(
      type="text"
      :placeholder="$t('newNamePlaceholder')"
      v-model="name"
      aria-label="$t('newNamePlaceholder')"
    )
  template(slot="modal-footer")
    .d-flex.justify-content-end
      om-button(primary @click="updateSubAccount()") {{ $t('apply') }}
</template>

<script>
  import UPDATE_SUBACCOUNT from '@/graphql/UpdateSubAccount.gql';

  export default {
    data() {
      return {
        subAccountId: null,
        name: '',
      };
    },

    methods: {
      beforeOpen(event) {
        this.subAccountId = event.params.subAccountId;
        this.name = event.params.name;
      },
      updateSubAccount() {
        this.$apollo
          .mutate({
            mutation: UPDATE_SUBACCOUNT,
            variables: {
              input: {
                _id: this.subAccountId,
                name: this.name,
              },
            },
          })
          .then(() => {
            this.$emit('refreshSubAccounts');
            this.$modal.hide('sub-account-rename');
          });
      },
    },
  };
</script>
