var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-table managed-accounts-table"},[_c('om-table',{attrs:{"name":"managedAccounts","items":_vm.managedAccounts,"columns":_vm.columns,"allItemsCount":_vm.managedAccounts.length,"selectable":false,"columnClasses":_vm.columnClasses,"serverSort":false,"serverPaginate":false,"sorting":_vm.sorting,"pagination":_vm.pagination,"customSortFields":_vm.customSortFields},on:{"paginationChange":function($event){_vm.pagination = $event}},scopedSlots:_vm._u([{key:"login.email",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"brand-link",attrs:{"title":rowData.row.login.email},on:{"click":function($event){return _vm.logInto(rowData.row)}}},[_vm._v(_vm._s(rowData.row.login.email))])]}},{key:"settings.domains",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm.getActiveDomains(rowData)))])]}},{key:"account.billing.package",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm.getPackageStr(rowData.row)))])]}},{key:"account.limits.maxPageViews",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_c('span',[_vm._v(_vm._s(_vm._f("thousandSep")(_vm.max(rowData.row))))])])]}},{key:"account.limits.pageViews",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-end"},[_c('div',{staticClass:"progress flex-grow-1 mx-3",staticStyle:{"max-width":"100px"}},[_c('div',{staticClass:"progress-bar",style:({ width: _vm.visitorPercent(_vm.used(rowData.row), _vm.max(rowData.row)) + '%' }),attrs:{"role":"progressbar","aria-valuenow":_vm.visitorPercent(_vm.used(rowData.row), _vm.max(rowData.row)),"aria-valuemin":"0","aria-valuemax":"100"}})]),_c('div',{staticClass:"flex-column"},[_vm._v(_vm._s(_vm._f("thousandSep")(_vm.used(rowData.row))))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }